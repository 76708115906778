import { dynamicImportPage } from "./dynamicImportPages";

export const routes = [
  // { id: 1, name: 'main', path: '/', Component: dynamicImportPage('Main') },
  { id: 2, name: 'userCabinet', path: '/', Component: dynamicImportPage('UserCabinet') },
  { id: 3, name: 'signUp', path: '/sign-up', Component: dynamicImportPage('SignUp') },
  { id: 4, name: 'signIn', path: '/sign-in', Component: dynamicImportPage('SignIn') },
  { id: 5, name: 'profile', path: '/profile', Component: dynamicImportPage('Profile') },
  { id: 6, name: 'resetPassword', path: '/reset-password', Component: dynamicImportPage('ResetPassword') },
  { id: 7, name: 'workspaces', path: '/app/:id/workspaces', Component: dynamicImportPage('Workspaces'), },
  // { id: 8, name: 'workspace', path: 'app/:id/workspaces/:workspaceId', Component: dynamicImportPage('ResetPassword') },
  { id: 9, name: 'tables', path: '/app/:id/tables', Component: dynamicImportPage('Tables'), state: { type: 'table' } },
  { id: 10, name: 'tableSettings', path: '/app/:id/settings', Component: dynamicImportPage('TableSettings') },
  // { id: 10, name: 'table', path: '/:id/tables/:tableId', Component: dynamicImportPage('ResetPassword') },
]
