import { useNavigate } from "react-router-dom"
import { getFetcher, postFetcher } from "src/api"
// import { userService } from "src/api/services/User"
import { setRequestHeader, setXSRFToBody } from "src/utils/api"
import Cookies from "universal-cookie"

const cookies = new Cookies()

export const useAuth = () => {

  const navigate = useNavigate()

  const getUser = async () => {
    const { data } = await getFetcher(
      `/api/my/report/313?JSON_KV`,
      {
        headers: setRequestHeader('token')
      }
    )
    const user = data?.pop()

    if (!user.DB) return null

    const userModel = {
      id: user.user_id,
      name: user.Name,
      email: user.Email,
      avatar: user.avatar,
      phone: user.Phone,
      telegram: user.link,
      db: user.DB,
      balance: +user.Balance,
      companies: [],
      tarif: {
        left: + user.Count,
        count: 20000
      }
    }
    return userModel
  }

  const registration = async (email, password, repeated_password) => {
    const { data } = await postFetcher(
      `/my/register?JSON`,
      {
        email: email,
        regpwd: password,
        regpwd1: repeated_password,
        agree: true,
      }
    )

    if (!data.db) {
      const [message] = data
      return {
        error: true,
        message: message.error
      }
    }

    return {
      error: false,
      message: 'На ваш email придет письмо для подтверждения аккаунта',
    }
  }

  const login = async (login, password) => {
    const { data: credentionals } = await postFetcher(
      '/my/auth?JSON',
      { login: login, pwd: password },
    )

    if (credentionals.id) {

      localStorage.setItem('xsrf', credentionals._xsrf)
      localStorage.setItem('token', credentionals.token)
      cookies.set('my', credentionals.token, { maxAge: '604800', path: '/', secure: true })

      return {
        error: false,
        message: ''
      }
    }

    return {
      error: true,
      message: 'Данные не соответсвуют требованиям'
    }
  }

  const resetPassword = async (login) => {

    // const data = await userService.resetPassword(login)
    // const { details } = data

    // if (details)
    //   return {
    //     error: true,
    //     message: 'Аккаунт не подтвержден'
    //   }
    // return {
    //   error: false,
    //   message: 'Данные придут вам на почту'
    // }
  }

  const logout = () => {
    localStorage.clear()
    navigate('/sign-in')
  }

  return {
    // user,
    registration,
    resetPassword,
    login,
    logout,
    getUser
  }
}

/*
'https://accounts.google.com/o/oauth2/auth?client_id=315358679657-nh0svik9str529clch2p4n23mnmh61fu.apps.googleusercontent.com'
+'&redirect_uri=https://'+document.location.host+'/auth.asp&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile'+state;
*/