import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { BASE_URL, setRequestHeader, setXSRFToBody } from "src/utils/api"
import { v4 } from "uuid"
import { getFetcher, postFetcher } from ".."

// class UserService {
//   async update(userId, name, phone, email, link, avatar) {

//     const formData = new FormData()

//     formData.append('_xsrf', localStorage.getItem('xsrf'))

//     formData.append('t33', name)
//     formData.append('t30', phone)
//     formData.append('t41', email)
//     formData.append('t6941', link)

//     avatar && formData.append('t7011', avatar)

//     const { data } = await postFetcher(
//       `/api/my/_m_save/${userId}`,
//       formData,
//       { headers: setRequestHeader('token') }
//     )

//     if (data.obj) return data
//     return null
//   }

//   async getHistory(user) {
//     const { data } = await getFetcher(
//       `/api/my/report/1095?JSON_KV`,
//       { headers: setRequestHeader('token') }
//     )

//     const [history] = data
//     if (history?.Paid) {
//       return data.map(item => ({
//         id: v4(),
//         date: item.Paid,
//         text: item.Notes,
//         payment: item.Payment,
//         balance: user.balance
//       }))
//     }
//     return []
//   }

//   async changePassword(email, oldPassword, newPassword, repeatedNewPassword) {
//     const { data } = await postFetcher(
//       `/api/my/auth`,
//       {
//         change: 1,
//         login: email,
//         pwd: oldPassword,
//         npw1: newPassword,
//         npw2: repeatedNewPassword,
//         ...(setXSRFToBody('xsrf'))
//       },
//       {
//         headers: setRequestHeader('token')
//       }
//     )
//     if (data.token) {
//       localStorage.setItem('token', data.token)
//       localStorage.setItem('xsrf', data._xsrf)
//       return true
//     }
//     return null
//   }

//   async resetPassword(login) {
//     const { data } = await postFetcher(
//       `/api/my/auth?reset`,
//       {
//         db: 'my',
//         login,
//         ...(setXSRFToBody('xsrf'))
//       },
//       { headers: setRequestHeader('token') }
//     )
//     return data
//   }
// }


export const userAPI = createApi({
  reducerPath: 'userAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, headers: setRequestHeader('token') }),
  endpoints: builder => ({
    update: builder.mutation({
      query: ({ name, phone, email, link, avatar, userId }) => {
        const formData = new FormData()

        formData.append('_xsrf', localStorage.getItem('xsrf'))

        formData.append('t33', name)
        formData.append('t30', phone)
        formData.append('t41', email)
        formData.append('t6941', link)
        avatar && formData.append('t7011', avatar)

        return {
          url: `/api/my/_m_save/${userId}`,
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (updatedUser, _, { name, phone, email, link, avatar }) => {
        if (!updatedUser.id) return null
        return {
          name,
          email,
          // avatar: avatar,
          phone,
          telegram: link,
        }
      }
    }),
    changePassword: builder.mutation({
      query: ({ email, oldPassword, newPassword, repeatedNewPassword }) => {

        const formData = new FormData()

        formData.append('login', email)
        formData.append('pwd', oldPassword)
        formData.append('npw1', newPassword)
        formData.append('npw2', repeatedNewPassword)
        formData.append('_xsrf', localStorage.getItem('xsrf'))

        return {
          url: `/api/my/auth`,
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (userData) => {
        try {
          const { token, _xsrf } = userData

          localStorage.setItem('token', token)
          localStorage.setItem('xsrf', _xsrf)

          return {
            message: 'Пароль успешно сброшен',
            credentials: {
              token,
              xsrf: _xsrf
            }
          }

        } catch (error) {
          return {
            message: 'Не удалось сбросить пароль',
            error,
            credentials: null
          }
        }
      }
    }),
    resetPassword: builder.mutation({
      query: ({ email }) => {

        const formData = new FormData()

        formData.append('db', 'my')
        formData.append('login', email)
        formData.append('_xsrf', localStorage.getItem('xsrf'))

        return {
          url: `/api/my/auth?reset`,
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (userData) => {
        console.log('reset password:>> ', userData);
      }
    }),
    getUserHistory: builder.query({
      query: ({ user = {} }) => {
        return {
          url: `/api/my/report/1095?JSON_KV`,
        }
      },
      transformResponse: (userHistory, _, { user }) => {

        const isHistoryItem = userHistory[0]?.Paid

        if (isHistoryItem)
          return userHistory
            .map(item => ({
              id: v4(),
              date: item.Paid,
              text: item.Notes,
              payment: item.Payment,
              balance: user?.balance
            }))

        return []
      }
    }),
  }),
})

// export const userService = new UserService()