// export const BASE_URL = 'https://ideav.online/'
export const BASE_URL = 'https://s0.coolbase.io/'

export const setRequestHeader = (key) => {
  return ({ 'X-Authorization': localStorage.getItem(key) })
}

export const setXSRFToBody = (key) => {
  return ({ _xsrf: localStorage.getItem(key) })
}
// export const getRequestHeader = (key) => {
//   return ({ 'X-Authorization': localStorage.getItem(key) })
// }