export const appRegExp = {
  auth: {
    email: /^\S+@\S+\.\S+$/,
    password: /.{3,20}/,
    repeat_password: /.{8,20}/,
  },
  table: {
    separator: /\B(?=(\d{3})+(?!\d))/g
    // separator: /(\d)(?=(?:[0-9]{4})+\b)/gm
  }
}