import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import moment from "moment"
import Pako from "pako"
import { BASE_URL, setRequestHeader, setXSRFToBody } from "src/utils/api"
import { columnSettings } from "src/utils/columnSettings"
import { columnTypesId, columnTypesName } from "src/utils/columnTypesId"
import { getDateAndTime } from "src/utils/helpers"
import { imageList } from "src/utils/imageList"
import { v4 } from "uuid"
import { getFetcher, postFetcher } from ".."

class TableService {
  async create(title = '', selectedType = 3, dbName, emptyTable, tableId, isLink, options = { defaultText: '', type: 'single_text' }, baseColumnCells) {


    // let formatedOptions = Object
    //   .keys(otherOptions)
    //   .reduce((prev, key) => ({ ...prev, [`${key}`]: otherOptions[key] }), {})

    // // const en = Pako.deflate(JSON.stringify(formatedOptions))
    // // const dec = JSON.parse(Pako.inflate(en, { to: 'string' }))
    // // const en = Pako.gzip(JSON.stringify(formatedOptions))
    // // const dec = Pako.ungzip(en, { to: 'string' })
    // // const decD = btoa(Pako.gzip(JSON.stringify(formatedOptions)))
    // const en = btoa(JSON.stringify(formatedOptions))
    // const dec = JSON.parse(atob(en))
    // // // const en = btoa(`${formatedOptions}`)
    // // // const dec = JSON.parse(atob(en))
    // // const en = btoa(unescape(encodeURIComponent(formatedOptions)))
    // // const dec = decodeURIComponent(escape(atob(en)));
    // // const en = Buffer.from(JSON.stringify(formatedOptions)).toString('base64')
    // console.log('first', {
    //   formatedOptions,
    //   en,
    //   dec,
    //   enL:en.length,
    // })
    // return

    const { files, items, table, ...otherOptions } = options
    let createdType = null
    if (!table?.id) {
      const { data } = await postFetcher(
        `/api/${dbName}/_d_new/`,
        {
          val: (options.type === 'multy_select' || options.type === 'single_select') ? `__${title}` : `${title}`,
          t: selectedType,
          // unique: 1,
          // unique: 0,
          ...(setXSRFToBody('app_xsrf'))
        },
        { headers: setRequestHeader('app_token') }
      )
      createdType = data
    }

    if (Array.isArray(createdType)) {
      const error = createdType.pop()
      return {
        createdType: null,
        error
      }
    }

    let createdRef = null
    if (isLink) {

      const { data: createdTableRef } = await postFetcher(
        `/api/${dbName}/_d_ref/${createdType?.obj || options.table.id}`,
        { ...(setXSRFToBody('app_xsrf')) },
        { headers: setRequestHeader('app_token') }
      )
      createdRef = createdTableRef
    }

    if (emptyTable) {
      const column = {
        id: createdType?.obj,
        title: title,
        icon: 'text',
        type: columnTypesName[selectedType],
        ...columnSettings[columnTypesName[selectedType]],
        cells: [],
        options: {
          defaultText: '',
          type: 'single_text'
        }
      }

      return column
    }

    const { data: createdColumn } = await postFetcher(
      `/api/${dbName}/_d_req/${tableId}`,
      { t: createdRef?.obj || createdType.obj, ...(setXSRFToBody('app_xsrf')) },
      { headers: setRequestHeader('app_token') }
    )

    // if (baseColumnCells?.length) {
    //   const formData = new FormData()

    //   formData.append('up', 1)
    //   formData.append('_xsrf', localStorage.getItem('app_xsrf'))
    //   baseColumnCells.forEach(cell => formData.append(`t${cell.rowId}`, options.defaultText || options.defaultValue || ''))

    //   const { data: createdRow } = await postFetcher(
    //     `/api/${dbName}/_m_new/${createdColumn.id}`,
    //     formData,
    //     { headers: setRequestHeader('app_token') }
    //   )
    // }

    // if (options.type === 'multy_select') {
    let formatedOptions = Object
      .keys(otherOptions)
      // .reduce((prev, key) => ({
      //   ...prev, [`${key}`]: otherOptions[key]
      // }), {})
      .reduce((prev, key) => prev + `${key}="${otherOptions[key]}":`, ':')

    // const attributes = window.btoa(unescape(encodeURIComponent(formatedOptions)));
    if (options.multyData) formatedOptions = `${formatedOptions}MULTI:`
    //  
    const { data: columnAttributes } = await postFetcher(
      `/api/${dbName}/_d_attrs/${createdColumn.id}`,
      {
        // val: JSON.stringify(formatedOptions),
        val: formatedOptions,
        ...(setXSRFToBody('app_xsrf'))
      },
      { headers: setRequestHeader('app_token') }
    )

    // if (options.multyData) {
    //   const { data: ms } = await postFetcher(
    //     `/api/${dbName}/_d_multi/${createdColumn?.id}`,
    //     { ...(setXSRFToBody('app_xsrf')) },
    //     { headers: setRequestHeader('app_token') }
    //   )
    // }


    // if (baseColumnCells.length) {
    //   const formData = new FormData()

    //   formData.append('up', 1)
    //   formData.append('_xsrf', localStorage.getItem('app_xsrf'))

    //   baseColumnCells.forEach(cell => formData.append(`t${cell.id}`, options?.defaultText || options.defaultValue || ''))
    //   // console.log('formData :>> ', ...formData);
    //   const { data: createdRow } = await postFetcher(
    //     `/api/${dbName}/_m_new/${createdColumn.id}`,
    //     formData,
    //     { headers: setRequestHeader('app_token') }
    //   )
    // }


    if (items) {

      for (let i = 0; i < items.length; i++) {
        const { id, color, ...item } = items?.[i];

        // const value = Object.keys(item).reduce((prev, key) => prev + `${key}:${Object.is(item[key])};`, '')

        const { data } = await postFetcher(
          `/api/${dbName}/_m_new/${createdType.obj}`,
          {
            // [`t${createdType.obj}`]: JSON.stringify(item),
            [`t${createdType.obj}`]: `title:${item.title};color:${color.value};colorId:${color.id};`,
            // [`t${createdType.obj}`]: item,
            up: 1,
            ...(setXSRFToBody('app_xsrf'))
          },
          { headers: setRequestHeader('app_token') }
        )
      }
    }

    const columnOptions = Object
      .keys(otherOptions)
      .reduce((prev, key) => ({
        ...prev, [`${key}`]: otherOptions[key]
      }), {})

    const column = {
      id: createdColumn.id,
      title: title || table.title,
      icon: 'text',
      type: columnTypesName[selectedType],
      ...columnSettings[columnOptions.type],
      cells: [],
      options: columnOptions
    }
    // console.log('title,table.title', title,table.title)
    return column

    // if (isLink) {
    //   const { data: createdType } = !options.table.id
    //     ? await postFetcher(
    //       `/api/${dbName}/_d_new/`,
    //       {
    //         val: title,
    //         t: selectedType,
    //         unique: 1,
    //         ...(setXSRFToBody('app_xsrf'))
    //       },
    //       { headers: setRequestHeader('app_token') }
    //     )
    //     : {}

    // const { data: createdTableRef } = await postFetcher(
    //   `/api/${dbName}/_d_ref/${createdType?.obj || options.table.id}`,
    //   { ...(setXSRFToBody('app_xsrf')) },
    //   { headers: setRequestHeader('app_token') }
    // )

    //   const { data: createdColumn } = await postFetcher(
    //     `/api/${dbName}/_d_req/${tableId}`,
    //     { t: createdTableRef.obj, ...(setXSRFToBody('app_xsrf')) },
    //     { headers: setRequestHeader('app_token') }
    //   )
    //   const { files, items, ...otherOptions } = options
    //   console.log('files, items, ...otherOptions', files, items, otherOptions)

    // if (files || items) {
    //   for (let i = 0; i < (files?.length || items.length); i++) {
    //     const item = files?.[i] || items?.[i];
    //     const { data } = await postFetcher(
    //       `/api/${dbName}/_m_new/${createdType.obj}`,
    //       {
    //         [`t${createdType.obj}`]: JSON.stringify(item),
    //         up: 1,
    //         ...(setXSRFToBody('app_xsrf'))
    //       },
    //       { headers: setRequestHeader('app_token') }
    //     )
    //   }
    // }

    // return {
    //   id: createdType?.obj || createdColumn.obj,
    //   title: title,
    //   icon: 'text',
    //   type: {
    //     id: columnTypesId[columnTypesName[selectedType]],
    //     name: columnTypesName[selectedType]
    //   },
    //   ...columnSettings[columnTypesName[selectedType]],
    //   cells: []
    // }
    // }

    // const { data: createdType } = await postFetcher(
    //   `/api/${dbName}/_d_new/`,
    //   {
    //     val: title,
    //     t: selectedType,
    //     unique: 1,
    //     ...(setXSRFToBody('app_xsrf'))
    //   },
    //   { headers: setRequestHeader('app_token') }
    // )

    // const formatedOptions = Object
    //   .keys(otherOptions)
    //   .reduce((prev, key) => ({ ...prev, [`${key}`]: otherOptions[key] }), {})

    // if (!Array.isArray(createdType)) {
    //   if (emptyTable) {
    //     const mainColumn = {
    //       id: createdType.obj,
    //       title: title,
    //       icon: 'text',
    //       type: {
    //         id: columnTypesId[columnTypesName[selectedType]],
    //         name: columnTypesName[selectedType]
    //       },
    //       // ...columnSettings[columnTypesName[selectedType]],
    //       ...columnSettings[formatedOptions.type],
    //       cells: [],
    //       options: formatedOptions
    //     }
    //     return mainColumn
    //   }

    // const { data: createdColumn } = await postFetcher(
    //   `/api/${dbName}/_d_req/${tableId}`,
    //   { t: createdType.obj, ...(setXSRFToBody('app_xsrf')) },
    //   // { t: createdColumnWithAttributes.obj, ...(setXSRFToBody('app_xsrf')) },
    //   { headers: setRequestHeader('app_token') }
    // )

    // const { data: createdColumnWithAttributes } = await postFetcher(
    //   `/api/${dbName}/_d_attrs/${createdColumn.id}`,
    //   {
    //     val: JSON.stringify(formatedOptions),
    //     ...(setXSRFToBody('app_xsrf'))
    //   },
    //   { headers: setRequestHeader('app_token') }
    // )

    //   if (!Array.isArray(createdColumn)) {
    //   const column = {
    //     id: createdColumn.obj,
    //     title: title,
    //     icon: 'text',
    //     type: {
    //       id: columnTypesId[columnTypesName[selectedType]],
    //       name: columnTypesName[selectedType]
    //     },
    //     // ...columnSettings[columnTypesName[selectedType]],
    //     ...columnSettings[formatedOptions.type],
    //     cells: [],
    //     options: formatedOptions
    //   }
    //   return column
    // }
    // }
    return null
  }

  // async createRow(dbName, columns, mainTableId, value = `Новая запись`) {
  async createRow(dbName, columns, mainColumn, user) {

    // console.log('columns :>> ', columns, userId);
    const cells = columns[0].cells
    // return
    const formData = new FormData()

    formData.append('up', 1)
    formData.append('_xsrf', localStorage.getItem('app_xsrf'))
    // formData.append(`t${mainTableId}`, Math.random())
    formData.append('unique', 0)
    formData.append(`t${mainColumn.id}`, `Новая запись ${cells.length + 1}`)
    // formData.append(`t${mainTableId}`, ' ')
    columns
      .forEach((column, columnIndex) =>
        formData
          .append(`t${column.id}`,
            column.options.type === 'created_by'
              ? '274'
              : (
                column.options.defaultText ||
                column.options.defaultValue ||
                columnSettings[column.options.type].defaultValue
              )
            // (columnIndex === 0)
            // ? value
            // : columnSettings[column.options.type].defaultValue
          )
      )
    // formData.append(`t${mainTableId}`, ' ')

    const { data: createdRow } = await postFetcher(
      `/api/${dbName}/_m_new/${mainColumn.id}`,
      formData,
      { headers: setRequestHeader('app_token') }
    )

    if (Array.isArray(createdRow) || createdRow?.error)
      return {
        updatedColumns: null,
        updatedMainColumn: null
      }


    const updatedMainColumn = {
      ...mainColumn,
      cells: [
        ...mainColumn.cells,
        {
          id: v4(),
          value: createdRow.val,
          type: mainColumn.type,
          rowId: createdRow.id,
          columnId: mainColumn.id,
          disabled: true,
          selected: false,
          isEdit: false,
          options: mainColumn.options
        }
      ]
    }

    const updatedColumns = columns
      .map(column => ({
        ...column,
        cells: [
          ...column.cells,
          {
            id: v4(),
            value: column.options.type === 'created_by'
              ? user.name
              : column.options.defaultText ||
              column.options.defaultValue ||
              columnSettings[column.options.type].defaultValue ||
              '',
            type: column.type,
            rowId: createdRow.id,
            columnId: column.id,
            disabled: true,
            selected: false,
            isEdit: false,
            options: column.options
          }
        ]
      }))
    return {
      updatedMainColumn,
      updatedColumns
    }
    // return {
    // id: v4(),
    // value: createdRow.val,
    // type: type.base,
    // columnId: type.id,
    // disabled: true,
    // selected: false,
    // isEdit: false,
    // options: mainColumn.options
    // }
    // if (!Array.isArray(createdRow)) {
    //   const row = {
    //     id: createdRow.id,
    //     selected: false,
    //     cells: columns
    //       .map((column, index) => ({
    //         id: v4(),
    //         columnId: column.id,
    //         type: column.type,
    //         value: !index ? createdRow.val : '',
    //         disabled: true,
    //       }))
    //   }
    //   return row
    // }
  }

  async getOne(tableId, dbName, existColumns) {
    // const { data: edit_types } = await getFetcher(
    //   `/api/${dbName}/edit_types?JSON_KV`,
    //   { headers: setRequestHeader('app_token') }
    // )

    const { data } = await getFetcher(
      `/api/${dbName}/object/${tableId}?JSON_KV`,
      { headers: setRequestHeader('app_token') }
    )

    let columns = []

    if (Array.isArray(data)) return null

    const {
      object,
      req_base,
      req_order,
      req_base_id,
      req_type,
      req_attrs,
      ref_type,
      reqs,
      type
    } = data

    let attribute = null
    // attribute = decodeURIComponent(escape(window.atob(req_attrs?.[type.id])));
    // attribute = decodeURIComponent(escape(atob(req_attrs?.[type.id])));
    try {
      const attributes = req_attrs?.[type.id].replace(':MULTI:', '').split(':')
      attributes
        .forEach(attr => {
          const [key, value] = attr.split('=')

          const endValue = JSON.parse(value)

          attribute = {
            ...attribute,
            [key]: endValue === 'true'
              ? true
              : endValue === 'false'
                ? false
                : endValue
          }
        })
      if (ref_type?.[type.id])
        attribute = {
          ...attribute,
          table: { id: ref_type[type.id], title: req_type[type.id] }
        }
      // attribute = JSON?.parse(req_attrs?.[type.id])
      // console.log('object :>> ', attribute);
    } catch (error) {
      // console.log('error :>> ', error);
      attribute = { defaultText: '', type: 'single_text' }
    }

    let mainColumn = {
      id: type.id,
      title: type.val,
      type: type.base,
      ...columnSettings[attribute?.type || 'single_text'],
      hidden: false,
      isEdit: false,
      cells: [],
      checked: false,
      options: attribute
    }

    if (req_type) {
      req_order
        .forEach(order => {

          // const attribute = req_attrs?.[order] ? JSON.parse(req_attrs?.[order]) : { defaultText: '', type: 'single_text' }
          let attribute = null
          // console.log('attributes', attributes, attribute)
          try {
            const attributes = req_attrs?.[order].replace(':MULTI:', '').split(':')
            attributes
              .forEach(attr => {
                if (attr) {
                  const [key, value] = attr.split('=')

                  const endValue = JSON.parse(value)

                  attribute = {
                    ...attribute,
                    [key]: endValue === 'true'
                      ? true
                      : endValue === 'false'
                        ? false
                        : endValue
                  }
                }
                // else
                //   console.log('first', attr)
                //   attribute = { defaultText: '', type: 'single_text' }
              })
            // console.log('attributes :>> ', attributes);
            if (ref_type?.[order])
              attribute = {
                ...attribute,
                table: { id: ref_type[order], title: req_type[order] }
              }
            // console.log('attributes', attributes, attribute)
            // attribute = JSON?.parse(req_attrs?.[order])
          } catch (error) {
            attribute = { defaultText: '', type: 'single_text' }
          }

          const column = {
            id: +order,
            title: req_type[order],
            type: req_base[order],
            // ...columnSettings[req_base[order]],
            ...columnSettings[attribute?.type || 'single_text'],
            hidden: false,
            checked: false,
            isEdit: false,
            cells: [],
            options: attribute
          }
          columns = [...columns, column]
        })
    }

    if (object) {
      const nextRows = object

      mainColumn = {
        ...mainColumn,
        cells: nextRows
          .map(row => ({
            // id: +row.id,
            id: v4(),
            value: row.val,
            type: type.base,
            columnId: type.id,
            disabled: true,
            selected: false,
            isEdit: false,
            options: mainColumn.options,
            columnId: mainColumn.id,
            rowId: +row.id
          }))
      }

      columns = columns
        .map((column, columnIndex) => ({
          ...column,
          cells: nextRows
            .map((row, rowIndex) => ({
              id: v4(),
              // id: +row.id,
              value: reqs?.[row.id]?.[column.id] || '',
              type: column.type,
              columnId: column.id,
              disabled: true,
              selected: false,
              isEdit: false,
              options: column.options,
              columnId: column.id,
              rowId: +row.id
            }))
        }))
    }
    // console.log('columns :>> ', [mainColumn, ...columns]);
    return { columns, mainColumn }

  }

  async getAll(dbName, columns) {
    const { data } = await getFetcher(
      `/api/${dbName}/dict?JSON_KV`,
      { headers: setRequestHeader('app_token') }
    )

    if (Array.isArray(data))
      return {
        tables: [],
        defaultTables: [],
      }

    const tables = Object
      .keys(data)
      .map(key => ({
        id: +key,
        title: data[key]
      }))

    return {
      tables: tables
        .slice(9, tables.length)
        .filter(({ title }) => !title.includes('__')),
      defaultTables: tables.splice(0, 9)
    }
  }

  async saveCellData(dbName, rowId, columnId, value) {
    const formData = new FormData()
    formData.append('_xsrf', localStorage.getItem('app_xsrf'))
    formData.append(`t${columnId}`, value)

    const { formatedDate } = getDateAndTime(moment())

    formData.append(`t664`, formatedDate)

    const data = await postFetcher(
      `/api/${dbName}/_m_save/${rowId}`,
      formData,
      // {
      //   [`t${columnId}`]: value,
      //   ...(setXSRFToBody('app_xsrf'))
      // },
      { headers: setRequestHeader('app_token') }
    )
  }

  async saveSelectOption(dbName, rowId, columnId, value, multyData) {

    const formData = new FormData()
    formData.append('_xsrf', localStorage.getItem('app_xsrf'))
    formData.append(`t${columnId}`, value)

    // const { formatedDate } = getDateAndTime(moment())
    // formData.append(`t664`, formatedDate)


    const data = await postFetcher(
      !multyData
        ? `/api/${dbName}/_m_save/${rowId}`
        : `/api/${dbName}/_m_set/${rowId}`,
      formData,
      { headers: setRequestHeader('app_token') }
    )
  }

  async deleteSelectOption(dbName, optionId) {

    const formData = new FormData()
    formData.append('_xsrf', localStorage.getItem('app_xsrf'))

    // const { formatedDate } = getDateAndTime(moment())
    // // formData.append(`t664`, formatedDate)

    const data = await postFetcher(
      `/api/${dbName}/_m_del/${optionId}`,
      formData,
      { headers: setRequestHeader('app_token') }
    )
  }

  // async getOptionInfo(dbName,) {

  //   const formData = new FormData()
  //   formData.append('_xsrf', localStorage.getItem('app_xsrf'))

  //   // const { formatedDate } = getDateAndTime(moment())
  //   // // formData.append(`t664`, formatedDate)

  //   const data = await postFetcher(
  //     `/api/${dbName}/_m_del/${optionId}`,
  //     formData,
  //     { headers: setRequestHeader('app_token') }
  //   )
  // }

  // _d_req creating column
  // _m_new creating row

  // создание первой колоник одним запросом (_d_new строка 8 в эксель)
  // остлаьные колонки (сначала _d_new затем вторым запросом идет _d_req и берется id из первого запроса)
}

export const tableAPI = createApi({
  reducerPath: 'tableAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, headers: setRequestHeader('app_token') }),
  endpoints: builder => ({
    create: builder.mutation({
      query: ({
        name = '',
        dbName,
        isEmptyTable,
        selectedTableType = 3,
        isLink,
        tableId,
        options = { defaultText: '', type: 'single_text' },
      }) => {

        const formData = new FormData()

        const isSelect = (options.type === 'multy_select' || options.type === 'single_select')

        formData.append('val', isSelect ? `__${name}` : `${name}`)
        formData.append('t', selectedTableType)
        formData.append('_xsrf', localStorage.getItem('app_xsrf'))

        return {
          url: `/api/${dbName}/_d_new/`,
          method: 'POST',
          body: formData
        }
      },
      // async onQueryStarted({ isLink }, { dispatch, getState, queryFulfilled }) {
      //   console.log('args :>> ', isLink, getState());
      //   // const { tableAPI } = getState()
      //   // if (isLink) {
      //   //   const data = await tableAPI.config.
      //   // }

      // },
      transformResponse: (createdType, _, { name, isEmptyTable, selectedTableType }) => {

        if (Array.isArray(createdType)) {
          const error = createdType.pop()
          return {
            createdType: null,
            error
          }
        }

        if (isEmptyTable) {
          const column = {
            id: createdType?.obj,
            title: name,
            icon: 'text',
            type: columnTypesName[selectedTableType],
            ...columnSettings[columnTypesName[selectedTableType]],
            cells: [],
            options: {
              defaultText: '',
              type: 'single_text'
            }
          }
          return {
            column
          }
        }

        return {
          createdType
        }

      }
    }),
    createRef: builder.mutation({
      query: ({ dbName, tableId, }) => {

        const formData = new FormData()

        formData.append('_xsrf', localStorage.getItem('app_xsrf'))

        return {
          url: `/api/${dbName}/_d_ref/${tableId}`,
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (createdRef) => {

        if (Array.isArray(createdRef)) {
          const error = createdRef.pop()
          return {
            createdRef: null,
            error
          }
        }

        return {
          createdRef
        }

      }
    }),
    createReq: builder.mutation({
      query: ({ dbName, tableId, typeId, }) => {

        const formData = new FormData()

        formData.append('t', typeId)
        formData.append('_xsrf', localStorage.getItem('app_xsrf'))

        return {
          url: `/api/${dbName}/_d_req/${tableId}`,
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (createdReq) => {

        if (Array.isArray(createdReq)) {
          const error = createdReq.pop()
          return {
            createdReq: null,
            error
          }
        }

        return {
          createdReq
        }

      }
    }),
    createAttributes: builder.mutation({
      query: ({ dbName, columnId, options }) => {

        const { files, items, table, ...otherOptions } = options

        let attributes = Object
          .keys(otherOptions)
          .reduce((prev, key) => prev + `${key}="${otherOptions[key]}":`, ':')

        if (options.multyData) options = `${attributes}MULTI:`

        const formData = new FormData()

        formData.append('val', attributes)
        formData.append('_xsrf', localStorage.getItem('app_xsrf'))

        return {
          url: `/api/${dbName}/_d_attrs/${columnId}`,
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (createdAttributes) => {

        if (Array.isArray(createdAttributes)) {
          const error = createdAttributes.pop()
          return {
            createdAttributes: null,
            error
          }
        }

        return {
          createdAttributes
        }

      }
    }),
    createSelectOptions: builder.mutation({
      query: ({ dbName, typeId, option }) => {

        const formData = new FormData()

        formData.append(`t${typeId}`, JSON.stringify(option))
        formData.append('up', 1)
        formData.append('_xsrf', localStorage.getItem('app_xsrf'))

        return {
          url: `/api/${dbName}/_m_new/${typeId}`,
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (createdSelectOptions) => {

        if (Array.isArray(createdSelectOptions)) {
          const error = createdSelectOptions.pop()
          return {
            createdSelectOptions: null,
            error
          }
        }

        return {
          createdSelectOptions
        }

      }
    }),
  })
})

export const tableService = new TableService()