import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './scss/index.scss'
import App from './App';
import CheckAuth from './components/auth/CheckAuth';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/dist/locale/ru';
import { Provider } from 'react-redux';
import { createStore } from './redux/store/store';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
moment.locale('ru')

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = createStore()

const persistor = persistStore(store)

root.render(
  // <React.StrictMode>
  <Provider
    store={ store }
  >
    <Router>
      <CheckAuth>
        <PersistGate loading={ '...loading' } persistor={ persistor }>
          <App />
        </PersistGate>
      </CheckAuth>
    </Router>
  </Provider>
  // </React.StrictMode>
);