import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"

const defaultState = {
  selectedApp: null,
  appTables: [],
  selectedAppTableIndex: 0,
  deletedApps: []
}

const appAdapter = createEntityAdapter()

export const appSlice = createSlice({
  name: 'app',
  initialState: defaultState,
  reducers: {
    getSelectedApp(state, { payload }) {
      state.selectedApp = payload
    },
    getAppTables(state, { payload }) {
      state.appTables = payload
    },
    getSelectedAppTable(state, { payload }) {
      state.selectedAppTableIndex = payload
    },
    getDeletedApps(state, { payload }) {
      state.deletedApps = payload
    },
    addDeletedApp(state, { payload }) {
      state.deletedApps = [...state.deletedApps, payload]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      // customEntityAdapter.removeAll(state);
      appAdapter.removeAll(state)
    });
  }
})

const appReducer = appSlice.reducer
const appActions = appSlice.actions

export {
  appActions,
  appReducer
}