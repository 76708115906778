import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { authAPI } from 'src/api/services/Auth';
import { companyAPI, companyService } from 'src/api/services/Company';
import { AppContext } from 'src/context';
import { useAuth } from 'src/hooks/useAuth';
import { companyActions } from 'src/redux/reducers/company/CompanySlice';
import { userActions } from 'src/redux/reducers/user/UserSlice';
import Loader from '../Loader';

const { getUser } = userActions
// const { getCompanies, getCompanyIndex } = companyActions

function CheckAuth({ children }) {

  // const { getUser } = useAuth()
  // const { pathname } = useLocation()

  // const navigate = useNavigate()
  const dispatch = useDispatch()
  const { logout } = useAuth()

  // const user = useSelector((state) => state.userReducer.user)
  const token = useSelector((state) => state.userReducer.token)
  const { data: newUser, isLoading, isUninitialized } = authAPI.useGetUserQuery(token, { refetchOnMountOrArgChange: true })
  // const [user, setUser] = useState(null);
  // const [companies, setCompanies] = useState(null);
  // console.log('user,newUser,', user, newUser, error, isLoading)
  // const { data: requestUser, isLoading: requestUserLoading, isSuccess: requestUserSuccess, isUninitialized, isError } =
  //   authAPI.useGetUserQuery()

  // const { data: companies, isSuccess: companySuccess, isLoading: companyLoading } =
  //   companyAPI.useGetQuery({ dbName: 'my', reportId: 6909, isUninitialized }, { refetchOnMountOrArgChange: true })

  // const [selectedCompany, setSelectedCompany] = useState(0);

  // console.log('1 :>> ', 1);

  useEffect(() => {
    if (isLoading || isUninitialized) return
    if (!newUser) {
      // logout()
      return
    }
    dispatch(getUser(newUser))
    // console.log('user :>> ', user);
    // console.log('object :>> ', requestUserSuccess, companySuccess,requestUser);
    // if (!requestUserSuccess || !companySuccess) return
    // if (!requestUserLoading || !companyLoading) return
    // dispatch(getUser(requestUser))
    // dispatch(getCompanies(companies))
    // dispatch(getCompanyIndex())
    // console.log('1 :>> ', 1);
    // if (!requestUser) {
    //   localStorage.clear()
    //   navigate('/sign-in')
    // }
  }, [isLoading, newUser]);
  // console.log('companies', companies)
  // console.log('first', requestUser, isLoading,requestUserSuccess)

  // useEffect(() => {
  //   async function fetchData() {
  //     if (!pathname.match(/^\/(sign-up|reset-password)/g)) {
  //       const requiredUser = await getUser()
  //       const data = await companyService.get('my', 6909)

  //       if (!requiredUser) {
  //         localStorage.clear()
  //         navigate('/sign-in')
  //         return
  //       }

  //       !user && setUser(requiredUser)
  //       !companies && setCompanies(data)
  //     }
  //   }
  //   fetchData()
  // }, [pathname]);

  // return <AppContext.Provider value={ {
  //   // user,
  //   // setUser,
  //   // companies,
  //   // setCompanies,
  //   // selectedCompany,
  //   // setSelectedCompany
  // } }>
  //   {
  //     !isLoading && children
  //   }
  // </AppContext.Provider>

  // if (!newUser) return <Navigate to={ '/sign-in' } replace />

  return <Loader
    isLoading={ isLoading }
  >
    { children }
  </Loader>
}

export default CheckAuth