import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ProtectedRouter from "./components/ProtectedRouter";
import { routes, unAthorizedRoutes } from "./components/routes";
// import moment from 'moment';
// import 'moment/dist/locale/ru';
// moment.locale('ru')

function App() {

  return (
    <div className="App">
      <Routes>
        {
          routes
            .map(({ id, path, Component }) =>
              <Route
                key={ id }
                path={ path }
                element={
                  <Suspense fallback={ 'loading' }>
                    { <Component /> }
                  </Suspense>
                }
              />
            )

        }
        <Route
          path="*"
          element={
            <Navigate
              to={ '/' }
              replace
            />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
