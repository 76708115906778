import moment from "moment"

export const columnSettings = {
  SHORT: {
    icon: 'text',
    cellView: 'single_text'
  },
  CHARS: {
    icon: 'long_text',
    cellView: 'long_text'
  },
  DATE: {
    icon: 'date',
    cellView: 'date'
  },
  NUMBER: {
    icon: 'number',
    cellView: 'number'
  },
  DATETIME: {
    icon: 'date',
    cellView: 'date',
  },
  FILE: {
    icon: 'file',
    cellView: 'attachment'
  },
  MEMO: {
    icon: 'm_select',
    cellView: 'multy_select'
  },
  PWD: {
    icon: 'text',
    cellView: 'single_text'
  },
  BOOLEAN: {
    icon: 'checkbox',
    cellView: 'checkbox'
  },
  REPORT_COLUMN: {
    icon: 'link_to',
    cellView: 'single_text'
  },
  BUTTON: {
    icon: 'button',
    cellView: 'button'
  },
  GRANT: {
    icon: 'text',
    cellView: 'single_text'
  },
  PATH: {
    icon: 'url',
    cellView: 'url'
  },
  HTML: {
    icon: 'text',
    cellView: 'single_text'
  },



  // LINK: {
  //   icon: 'link_to',
  //   cellView: 'link_to'
  // },
  link_to: {
    icon: 'link_to',
    cellView: 'link_to',
    defaultValue: ''
  },
  single_text: {
    icon: 'text',
    cellView: 'single_text',
    defaultValue: ''
  },
  long_text: {
    icon: 'long_text',
    cellView: 'long_text',
    defaultValue: ''
  },
  user: {
    icon: 'user',
    cellView: 'user',
    defaultValue: ''
  },
  attachment: {
    icon: 'file',
    cellView: 'attachment',
    defaultValue:''
  },
  checkbox: {
    icon: 'checkbox',
    cellView: 'checkbox',
    defaultValue:''
  },
  // MULTY_SELECT: {
  //   icon: 'm_select',
  //   cellView: 'multy_select',
  // defaultValue:''
  // },
  // SINGLE_SELECT: {
  //   icon: 'select',
  //   cellView: 'single_select',
  // defaultValue:''
  // },
  multy_select: {
    icon: 'm_select',
    cellView: 'multy_select',
    defaultValue: ''
  },
  single_select: {
    icon: 'select',
    cellView: 'single_select',
    defaultValue: ''
  },
  date: {
    icon: 'date',
    cellView: 'date',
    // defaultValue: new Date()
    defaultValue: moment().format(`DD.MM.YYYY HH:mm:ss`)
  },
  phone: {
    icon: 'phone',
    cellView: 'phone',
    defaultValue: ''
  },
  email: {
    icon: 'mail',
    cellView: 'email',
    defaultValue: ''
  },
  url: {
    icon: 'url',
    cellView: 'url',
    defaultValue: ''
  },
  number: {
    icon: 'number',
    cellView: 'number',
    defaultValue: ''
  },
  currency: {
    icon: 'currency',
    cellView: 'currency',
    defaultValue: ''
  },
  percent: {
    icon: 'percent',
    cellView: 'percent',
    defaultValue: ''
  },
  duration: {
    icon: 'duration',
    cellView: 'duration',
    defaultValue: moment().format(`DD.MM.YYYY HH:mm:ss`)
  },
  raiting: {
    icon: 'raiting',
    cellView: 'raiting',
    defaultValue: 0
  },
  formula: {
    icon: 'formula',
    cellView: 'formula',
    defaultValue: ''
  },
  rollup: {
    icon: 'rollup',
    cellView: 'rollup',
    defaultValue: ''
  },
  count: {
    icon: 'count',
    cellView: 'count',
    defaultValue: ''
  },
  lookup: {
    icon: 'lookup',
    cellView: 'lookup',
    defaultValue: ''
  },
  created_time: {
    icon: 'time',
    cellView: 'created_time',
    defaultValue: moment().format(`DD.MM.YYYY HH:mm:ss`)
  },
  last_modified: {
    icon: 'time',
    cellView: 'last_modified',
    defaultValue: moment().format(`DD.MM.YYYY HH:mm:ss`)
  },
  created_by: {
    icon: 'user_v2',
    cellView: 'created_by',
    defaultValue: ''
  },
  last_modified_by: {
    icon: 'user_v2',
    cellView: 'last_modified_by',
    defaultValue: ''
  },
  auto_number: {
    icon: 'number_v2',
    cellView: 'auto_number',
    defaultValue: ''
  },
  button: {
    icon: 'button',
    cellView: 'button',
    defaultValue: ''
  },
}
