import { createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"

const defaultState = {
  selectedTable: null,
  selectedTableIndex: 0,
}


export const tableSlice = createSlice({
  name: 'table',
  initialState: defaultState,
  reducers: {
    getSelectedTable(state, { payload }) {
      state.selectedTable = payload
    },
    getSelectedTableIndex(state, { payload }) {
      state.selectedTableIndex = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      // customEntityAdapter.removeAll(state);
        // companyAdapter.removeAll(state)
    });
  }
})

export const { reducer: tableReduer, actions: tableActions } = tableSlice