export const imageList = {
  logo: '/imgs/logo_v2.svg',
  avatar: '/imgs/avatar.png',
  app: '/imgs/icons/app.svg',
  arrow_down: '/imgs/icons/arrow-down.svg',
  arrow_left: '/imgs/icons/arrow-left.svg',
  arrow_right: '/imgs/icons/arrow-right.svg',
  arrow_up: '/imgs/icons/arrow-up.svg',
  bird: '/imgs/icons/bird.svg',
  carrot_down: '/imgs/icons/carrot-down.svg',
  checked: '/imgs/icons/checked.svg',
  close: '/imgs/icons/close.svg',
  company: '/imgs/icons/company.svg',
  copy: '/imgs/icons/copy.svg',
  edit: '/imgs/icons/edit.svg',
  edit_v2: '/imgs/icons/edit-v2.svg',
  exit: '/imgs/icons/exit.svg',
  expand: '/imgs/icons/expand.svg',
  globe: '/imgs/icons/globe.svg',
  google: '/imgs/icons/google.svg',
  info: '/imgs/icons/info.svg',
  key: '/imgs/icons/key.svg',
  lock: '/imgs/icons/lock.svg',
  mail: '/imgs/icons/mail.svg',
  navigation: '/imgs/icons/navigation.svg',
  notify: '/imgs/icons/notify.svg',
  person: '/imgs/icons/person.svg',
  plus_box_light: '/imgs/icons/plus-box-light.svg',
  plus_box: '/imgs/icons/plus-box.svg',
  plus: '/imgs/icons/plus.svg',
  question: '/imgs/icons/question.svg',
  question_v2: '/imgs/icons/question_v2.svg',
  search: '/imgs/icons/search.svg',
  settings: '/imgs/icons/settings.svg',
  sort_down: '/imgs/icons/sort-down.svg',
  sort_up: '/imgs/icons/sort-up.svg',
  tag: '/imgs/icons/tag.svg',
  ticket: '/imgs/icons/ticket.svg',
  trash: '/imgs/icons/trash.svg',
  video: '/imgs/icons/video.svg',
  barcode: '/imgs/icons/table/barcode.svg',
  button: '/imgs/icons/table/button.svg',
  checkbox: '/imgs/icons/table/checkbox.svg',
  color: '/imgs/icons/table/color.svg',
  count: '/imgs/icons/table/count.svg',
  currency: '/imgs/icons/table/currency.svg',
  date: '/imgs/icons/table/date.svg',
  duration: '/imgs/icons/table/duration.svg',
  // email: '/imgs/icons/table/email.svg',
  file: '/imgs/icons/table/file.svg',
  filter: '/imgs/icons/table/filter.svg',
  formula: '/imgs/icons/table/formula.svg',
  hide: '/imgs/icons/table/hide.svg',
  link_to: '/imgs/icons/table/link-to.svg',
  long_text: '/imgs/icons/table/long-text.svg',
  lookup: '/imgs/icons/table/lookup.svg',
  m_select: '/imgs/icons/table/m-select.svg',
  number: '/imgs/icons/table/number.svg',
  number_v2: '/imgs/icons/table/number-v2.svg',
  percent: '/imgs/icons/table/percent.svg',
  phone: '/imgs/icons/table/phone.svg',
  raiting: '/imgs/icons/table/raiting.svg',
  rollup: '/imgs/icons/table/rollup.svg',
  select: '/imgs/icons/table/select.svg',
  share: '/imgs/icons/table/share.svg',
  sort: '/imgs/icons/table/sort.svg',
  text: '/imgs/icons/table/text.svg',
  time: '/imgs/icons/table/time.svg',
  url: '/imgs/icons/table/url.svg',
  user_v2: '/imgs/icons/table/user-v2.svg',
  user: '/imgs/icons/table/user.svg',
  copy: '/imgs/icons/modal/copy.svg',
  delete: '/imgs/icons/modal/delete.svg',
  move: '/imgs/icons/modal/move.svg',
  upload: '/imgs/icons/modal/upload.svg',
  home: '/imgs/icons/content-tabs/home.svg',
  user_v3: '/imgs/icons/content-tabs/user.svg',
  plus_gray: '/imgs/icons/plus-gray.svg',
  users: '/imgs/icons/table-settings/users.svg',
  proccess: '/imgs/icons/table-settings/process.svg',
  dollar: '/imgs/icons/table-settings/dollar.svg',
  roles: '/imgs/icons/table-settings/roles.svg',
  calendar: '/imgs/icons/table-settings/calendar.svg',
  folder: '/imgs/icons/table-settings/folder.svg',
  import: '/imgs/icons/table-settings/import.svg',
  notification: '/imgs/icons/table-settings/notification.svg',
  printer: '/imgs/icons/table-settings/printer.svg',
  export: '/imgs/icons/table-settings/export.svg',
  shield: '/imgs/icons/table-settings/shield.svg',
  dots: '/imgs/icons/dots.svg',
  star_1: '/imgs/icons/raiting/star_1.svg',
  carrot_left: '/imgs/icons/carrot-left.svg',
}