import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BASE_URL, setRequestHeader, setXSRFToBody } from "src/utils/api"
import { iconName } from "src/utils/helpers"
import { postFetcher } from ".."

// class AppService {
//   async create(entityId, dbName, parentId, { title, color = '', icon = '' }) {
//     const { data } = await postFetcher(
//       `/api/${dbName}/_new_db`,
//       {
//         db: title,
//         company: parentId,
//         color,
//         icon: iconName(icon),
//         ...(setXSRFToBody('xsrf'))
//       },
//       { headers: setRequestHeader('token') }
//     )

//     const app = {
//       id: data.id,
//       title: title,
//       selectedColor: color,
//       selectedIcon: icon,
//     }

//     return data.id ? app : null
//   }

//   async update(entityId, dbName, { color = '', icon = '', companyId = null }) {
//     const { data } = await postFetcher(
//       `/api/${dbName}/_m_save/${entityId}`,
//       {
//         t6923: color,
//         t6925: iconName(icon),
//         ...(companyId ? { t6908: companyId } : {}),
//         ...(setXSRFToBody('xsrf'))
//       },
//       { headers: setRequestHeader('token') }
//     )

//     return data
//   }

//   async move(entityId, dbName, { companyId = null }) {
//     const { data } = await postFetcher(
//       `/api/${dbName}/_m_save/${entityId}`,
//       {
//         t6908: companyId,
//         ...(setXSRFToBody('xsrf'))
//       },
//       { headers: setRequestHeader('token') }
//     )

//     return data
//   }

//   async delete(entityId, dbName, isDeleted) {
//     const { data } = await postFetcher(
//       `/api/${dbName}/_m_save/${entityId}`,
//       {
//         t6921: isDeleted,
//         ...(setXSRFToBody('xsrf'))
//       },
//       { headers: setRequestHeader('token') }
//     )
//     return data
//   }
// }


export const appAPI = createApi({
  reducerPath: 'appAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, headers: setRequestHeader('token') }),
  endpoints: (bulder) => ({
    create: bulder.mutation({
      query: ({ dbName, parentId, app: { title, color, icon } }) => {

        const formData = new FormData()

        formData.append(`db`, title)
        formData.append(`t7091`, title)
        formData.append(`company`, parentId)
        formData.append(`color`, color)
        formData.append(`icon`, iconName(icon))
        formData.append(`_xsrf`, localStorage.getItem('xsrf'))

        return {
          url: `/api/${dbName}/_new_db`,
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (app, _, { parentId, app: { color, icon, title } }) => {
        if (!app.id)
          return null

        return {
          id: +app.id,
          title: title,
          companyId: parentId,
          selectedColor: color,
          selectedIcon: icon,
        }
      }
    }),
    update: bulder.mutation({
      query: ({ entityId, dbName, title = '', app: { color = '', icon = '', companyId = null } }) => {

        const formData = new FormData()

        formData.append(`t7091`, title)
        formData.append(`t6923`, color)
        formData.append(`t6925`, iconName(icon))
        companyId && formData.append('t6908', companyId)
        formData.append(`_xsrf`, localStorage.getItem('xsrf'))

        return {
          url: `/api/${dbName}/_m_save/${entityId}`,
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (app, _, { app: { color, icon, title } }) => {
        if (!app?.id)
          return null

        return {
          id: +app.obj,
          title: title,
          selectedColor: color,
          selectedIcon: icon,
        }
      }
    }),
    move: bulder.mutation({
      query: ({ entityId, dbName, app: { companyId = null } }) => {

        const formData = new FormData()

        formData.append(`t6908`, companyId)
        formData.append(`_xsrf`, localStorage.getItem('xsrf'))

        return {
          url: `/api/${dbName}/_m_save/${entityId}`,
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (app) => {

      }
    }),
    delete: bulder.mutation({
      query: ({ entityId, dbName, isDeleted }) => {

        const formData = new FormData()

        formData.append(`t6921`, isDeleted)
        formData.append(`_xsrf`, localStorage.getItem('xsrf'))

        return {
          url: `/api/${dbName}/_m_save/${entityId}`,
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (app, _, { isDeleted }) => {
        if (!app.id)
          return

        return {
          id: +app.obj,
          isDeleted
        }
      }
    }),
  })
})

// export const appService = new AppService()