import React, { useEffect } from 'react'
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth'

function ProtectedRouter({ children }) {

  const { getUser, logout } = useAuth()
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const requiredUser = await getUser()
      if (requiredUser) setUser(requiredUser)
      else logout()
    }
    fetchData()
  }, []);

  if (!user) return <Navigate to={ '/sign-in' } />
  return children
}

export default ProtectedRouter