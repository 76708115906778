import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, setRequestHeader, setXSRFToBody } from "src/utils/api";

export const authAPI = createApi({
  reducerPath: 'authAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, headers: setRequestHeader('token'), body: { ...setXSRFToBody('xsrf') } }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ login, password }) => {

        const userData = new FormData()

        userData.append('login', login)
        userData.append('pwd', password)

        return {
          url: `/my/auth?JSON`,
          method: 'POST',
          body: userData
        }
      },
      transformResponse: (user) => {
        if (!user?.id)
          return {
            error: true,
            message: 'Данные не соответсвуют требованиям'
          }

        localStorage.setItem('xsrf', user._xsrf)
        localStorage.setItem('token', user.token)

        return {
          error: false,
          message: '',
          token: user.token,
          xsrf: user._xsrf,
        }
      },
      // async onQueryStarted(arg, { dispatch, getState }) {
      //   dispatch()
      // },
    }),
    registration: builder.mutation({
      query: ({ email, password, repeated_password }) => ({
        url: `/my/register?JSON`,
        body: {
          email: email,
          regpwd: password,
          regpwd1: repeated_password,
          agree: true,
        }
      }),
      transformResponse: (user) => {
        return user
      }
    }),
    getUser: builder.query({
      query: (token) => ({
        url: `/api/my/report/313?JSON_KV`,
        headers: setRequestHeader('token')
      }),
      transformResponse: (user) => {
        
        const currentUser = user?.pop()

        if (!currentUser.user_id) return null

        const userModel = {
          id: currentUser.user_id,
          name: currentUser.Name,
          email: currentUser.Email,
          avatar: currentUser.avatar,
          phone: currentUser.Phone,
          telegram: currentUser.link,
          db: currentUser.DB,
          balance: +currentUser.Balance,
          companies: [],
          tarif: {
            left: + currentUser.Count || 0,
            count: 20000
          }
        }

        return userModel
      }
    }),
  })
})