import moment from "moment"
import { appRegExp } from "src/regexp"

const texarea = document.createElement('textarea')
export const decodeHTMLEntity = (value) => {
  texarea.innerHTML = value
  return texarea.value
}

export const formatValue = (value, mask, allowNegative = false) => {

  const stringifyValue = `${value}`
  if (!stringifyValue.match(/[\d\w]/)) return ''

  let separatedValue = stringifyValue?.replace(appRegExp['table'].separator, ' ')

  // const reg = new RegExp(`^[0-9]{0,${stringifyValue.length-mask.length}}(\\.\\d[0-9]{0,${value.length - 1}})?$`)

  // if (mask && stringifyValue) separatedValue = `${separatedValue}`.replace(`\B(?=(\d{${stringifyValue.length - mask.length}}})+(?!\d))/`, '.')
  // if (mask && stringifyValue) separatedValue = `${separatedValue}.${mask}`
  if (mask && stringifyValue) separatedValue = `${separatedValue}`
  if (!allowNegative) separatedValue = separatedValue?.replace(/\-/, '')

  return separatedValue
}

export const checkPassword = (password) => {
  if (!password || !appRegExp['auth'].password.test(password)) {
    return {
      checked: false,
      message: 'Пароль должен быть не меньше 8 символов'
    }
  }
  return {
    checked: true,
    message: ''
  }
}

export const checkEmail = (email) => {
  if (!email || !appRegExp['auth'].email.test(email)) {
    return {
      checked: false,
      message: 'E-mail не соответствует требованиям'
    }
  }
  return {
    checked: true,
    message: ''
  }
}

export const comparePasswords = (firstPassword, secondPassowrd) => {
  if (!firstPassword || firstPassword.length < 8) {
    return {
      checked: false,
      message: 'Пароль долен быть не меньше 8 символов'
    }
  }

  if (!secondPassowrd || secondPassowrd.length < 8) {
    return {
      checked: false,
      message: 'Пароль долен быть не меньше 8 символов'
    }
  }

  if (firstPassword !== secondPassowrd) {
    return {
      checked: false,
      message: 'Пароль не совпадают'
    }
  }

  return {
    checked: true,
    message: ''
  }
}

export const iconName = (icon) => icon.split('/').pop()

export const getDateAndTime = (fullDate) => {

  try {
    const [day, month, year, times] = fullDate.split(/[\.\s]/)
    const formatedDate = moment(`${year}-${month}-${day}T${times}`).format()

    const date = new Date(formatedDate)
    const time = new Date(formatedDate)

    return {
      date,
      time,
      formatedDate
    }
  } catch (error) {
    const formatedDate = moment(fullDate).format('YYYY-MM-DDTHH:mm:ss')
    return {
      formatedDate
    }
  }
}