import axios from "axios"
import { BASE_URL } from "src/utils/api"

const api = axios.create({
  // withCredentials: true,
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
})

api.interceptors.request.use((config) => {
  // if (localStorage.getItem('app_token')) {

  //   return config
  // }
  // if (localStorage.getItem('token'))
  //   config.headers = {
  //     ...config.headers,
  //     'X-Authorization': localStorage.getItem('token'),
  //   }
  // if (localStorage.getItem('app_token') || localStorage.getItem('token')) {
  //   config.headers = {
  //     ...config.headers,
  //     'X-Authorization': localStorage.getItem('app_token') || localStorage.getItem('token')
  //   }
  // }
  // config.data = {
  //   ...config.data,
  //   _xsrf: localStorage.getItem('xsrf')
  // }
  return config
})

export const postFetcher = (route, data = {}, config = {}) =>
  api
    .post(route, data, config)
    .then(({ data, status }) => ({ status, data }))
    .catch(({ response }) => ({ status: 500, message: 'smth went wrong' }))

export const getFetcher = (route, config = {}) =>
  api
    .get(route, config)
    .then(({ data, status }) => ({ status, data }))
    .catch(({ response }) => ({ status: 500, message: 'smth went wrong' }))

export const putFetcher = (route, data = {}, config = {}) =>
  api
    .put(route, data, config)
    .then(({ data, status }) => ({ status, data }))
    .catch(({ response }) => ({ status: 500, message: 'smth went wrong' }))

export const deleteFetcher = (route, data = {}, config = {}) =>
  api
    .delete(route, data, config)
    .then(({ data, status }) => ({ status, data }))
    .catch(({ response }) => ({ status: 500, message: 'smth went wrong' }))