import { combineReducers, configureStore } from "@reduxjs/toolkit"
import localforage from "localforage"
import persistReducer from "redux-persist/es/persistReducer"
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import { appAPI } from "src/api/services/App"
import { authAPI } from "src/api/services/Auth"
import { companyAPI } from "src/api/services/Company"
import { tableAPI } from "src/api/services/TableService"
import { userAPI } from "src/api/services/User"
import { appReducer } from "../reducers/app/AppSlice"
import { companyReducer } from "../reducers/company/CompanySlice"
import { tableReduer } from "../reducers/table/TableSlice"
import { userReducer } from "../reducers/user/UserSlice"
import storage from "redux-persist/lib/storage"



const persitConfig = {
  key: 'root',
  // storage: localforage,
  storage,
  blacklist: [
    companyAPI.reducerPath,
    authAPI.reducerPath,
    appAPI.reducerPath,
    userAPI.reducerPath,
    tableAPI.reducerPath,
  ]
}

const companyPersistConfig = {
  key: 'companyPersist',
  // storage: localforage,
  storage,
  // blacklist: ['companies', 'company', 'isLoading', 'error'],
  whitelist: ['selectedCompanyIndex']
}

const userPersistConfig = {
  key: 'userPersist',
  // storage: localforage,
  storage,
  whitelist: ['isAuthorized', 'token', 'xsrf']
}

const appPersistConfig = {
  key: 'appPersist',
  // storage: localforage,
  storage,
  whitelist: ['selectedApp', 'selectedAppTable','selectedAppTableIndex']
}

// const tablePersistConfig = {
//   key: 'tablePersist',
//   storage: localforage,
//   whitelist: ['tableReducer']
// }

const rootReducer = combineReducers({
  companyReducer: persistReducer(companyPersistConfig, companyReducer),
  userReducer: persistReducer(userPersistConfig, userReducer),
  appReducer: persistReducer(appPersistConfig, appReducer),
  tableReduer,
  [companyAPI.reducerPath]: companyAPI.reducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [appAPI.reducerPath]: appAPI.reducer,
  [userAPI.reducerPath]: userAPI.reducer,
  [tableAPI.reducerPath]: tableAPI.reducer,
})

const configurePersistReducer = persistReducer(persitConfig, rootReducer)

export const createStore = () => configureStore({
  // reducer: rootReducer,
  reducer: configurePersistReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }
    })
      .concat(companyAPI.middleware)
      .concat(authAPI.middleware)
      .concat(appAPI.middleware)
      .concat(userAPI.middleware)
      .concat(tableAPI.middleware)
})



