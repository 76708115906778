import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"

const defaultState = {
  isLoading: false,
  error: '',
  companies: [],
  company: null,
  selectedCompanyIndex: 0
}


const companyAdapter = createEntityAdapter()

const companySlice = createSlice({
  name: 'companies',
  initialState: defaultState,
  reducers: {
    createCompany(state, { payload: company }) {
      if (!+company.id) state.company = company
      else {
        state.companies = [...state.companies, company]
        state.company = null
      }
    },
    cancelCreateCompany(state, { payload: company }) {
      state.company = null
    },
    getCompanies(state, { payload }) {
      state.companies = payload
    },
    getCompany(state, { payload }) {
      state.company = payload
    },
    updateCompany(state, { payload }) {
      const { createdCompanyId, ...otherFields } = payload

      state.companies = state
        .companies
        .map(company =>
          company.id === createdCompanyId
            ? {
              ...company,
              ...otherFields
            }
            : company
        )
    },
    updateCompanyTitle(state, { payload }) {

      state.company = {
        ...state.company,
        name: payload
      }

    },
    deleteCompany(state, { payload }) {
      state.companies = state
        .companies
        .filter(company => company.id !== payload)
    },
    getCompanyIndex(state, { payload }) {
      state.selectedCompanyIndex = payload
      // if (payload) state.selectedCompanyIndex = payload
      // else {

      //   const actualCompanies = state.companies.filter(company => !company.isDeleted)
      //   const lastCompany = actualCompanies.pop()
      //   const lastCompanyIndex = state.companies.findIndex(company => company.id === lastCompany.id)

      //   state.selectedCompanyIndex = lastCompanyIndex
      // }
    },
    addApp(state, { payload }) {
      const { companyIndex, app } = payload
      state.companies = Object
        .values({
          ...state.companies,
          [companyIndex]: {
            ...state.companies[companyIndex],
            apps: [...state.companies[companyIndex].apps, app]
          }
        })
    },
    updateApp(state, { payload }) {
      const { companyIndex, appId, moveToCompany, ...updatedFields } = payload

      const targetApp = state.companies[companyIndex].apps.find(app => app.id === appId)
      const movableCompanyIndex = state.companies.findIndex(company => company.id === moveToCompany.id)

      const updatedMovableCompany = {
        ...moveToCompany,
        apps: moveToCompany.id !== targetApp.companyId
          ? [
            ...moveToCompany.apps,
            {
              ...targetApp,
              ...updatedFields,
              companyId: moveToCompany.id,
            }
          ]
          : moveToCompany
            .apps
            .map(app =>
              app.id === targetApp.id
                ? {
                  ...app,
                  ...updatedFields
                }
                : app
            )
            .filter(app => !app.isDeleted)
      }

      state.companies = Object
        .values({
          ...state.companies,
          ...(
            state.companies[companyIndex].id !== updatedMovableCompany.id
              ? {
                [companyIndex]: {
                  ...state.companies[companyIndex],
                  apps: state.companies[companyIndex]
                    .apps
                    .filter(app => app.id !== appId)
                },
                [movableCompanyIndex]: updatedMovableCompany
              }
              : {
                [companyIndex]: updatedMovableCompany
              }
          )
        })
    },
    deleteApp(state, { payload }) {
      const { companyIndex, appId, moveToCompany, ...updatedFields } = payload

      const targetApp = state.companies[companyIndex].apps.find(app => app.id === appId)
      const movableCompanyIndex = state.companies.findIndex(company => company.id === moveToCompany.id)


    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      // customEntityAdapter.removeAll(state);
        companyAdapter.removeAll(state)
    });
  }
})

const companyReducer = companySlice.reducer
const companyActions = companySlice.actions

export {
  companyReducer,
  companyActions
}

