import { BOOLEAN, BUTTON, CALCULATABLE, CHARS, DATE, DATE_TIME, FILE, GRANT, HTML, MEMO, NUMBER, PATH, PWD, REPORT_COLUMN, SHORT, SIGNED } from "./columnTypes";

export const columnTypesId = {
  [SHORT]: 3,
  [CHARS]: 8,
  [DATE]: 9,
  [NUMBER]: 13,
  [SIGNED]: 14,
  [BOOLEAN]: 11,
  [MEMO]: 12,
  [DATE_TIME]: 4,
  [FILE]: 10,
  [HTML]: 2,
  [BUTTON]: 7,
  [PWD]: 6,
  [GRANT]: 5,
  [CALCULATABLE]: 15,
  [REPORT_COLUMN]: 16,
  [PATH]: 17,
}

export const columnTypesName = {
  3: SHORT,
  8: CHARS,
  9: DATE,
  13: NUMBER,
  14: SIGNED,
  11: BOOLEAN,
  12: MEMO,
  4: DATE_TIME,
  10: FILE,
  2: HTML,
  7: BUTTON,
  6: PWD,
  5: GRANT,
  15: CALCULATABLE,
  16: REPORT_COLUMN,
  17: PATH,
}