const SHORT = 'SHORT'
const CHARS = 'CHARS'
const DATE = 'DATE'
const NUMBER = 'NUMBER'
const SIGNED = 'SIGNED'
const BOOLEAN = 'BOOLEAN'
const MEMO = 'MEMO'
const DATE_TIME = 'DATETIME'
const FILE = 'FILE'
const HTML = 'HTML'
const BUTTON = 'BUTTON'
const PWD = 'PWD'
const GRANT = 'GRANT'
const CALCULATABLE = 'CALCULATABLE'
const REPORT_COLUMN = 'REPORT_COLUMN'
const PATH = 'PATH'
const LINK = 'LINK'
const USER = 'USER'
const MULTY_SELECT = 'MULTY_SELECT'
const SINGLE_SELECT = 'SINGLE_SELECT'
const PHONE = 'PHONE'
const EMAIL = 'EMAIL'
const URL = 'URL'
const CURRENCY = 'CURRENCY'
const PERCENT = 'PERCENT'
const DURATION = 'DURATION'

export {
  SHORT,
  CHARS,
  DATE,
  NUMBER,
  SIGNED,
  BOOLEAN,
  MEMO,
  DATE_TIME,
  FILE,
  HTML,
  BUTTON,
  PWD,
  GRANT,
  CALCULATABLE,
  REPORT_COLUMN,
  PATH,
  LINK,
  USER,
  MULTY_SELECT,
  SINGLE_SELECT,
  PHONE,
  EMAIL,
  URL,
  CURRENCY,
  PERCENT,
  DURATION
}