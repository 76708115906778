import React, { useEffect } from 'react'
import { useState } from 'react'

function Loader({ children, isLoading = false }) {

  return (
    <div
      className='loader'
    >
      {
        isLoading
          ? <div className="loader__inner">
            <div className='loader__block-circles'>
              <div className='loader__block-circle'></div>
              <div className='loader__block-circle'></div>
            </div>
          </div>
          : children
      }
    </div>
  )
}

export default Loader