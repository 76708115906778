import { BASE_URL, setRequestHeader, setXSRFToBody } from "src/utils/api"
import { getFetcher, postFetcher } from ".."
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// class CompanyService {
//   async create(dbName, title, userId) {
//     const { data } = await postFetcher(
//       `/api/${dbName}/_m_new/6904`,
//       {
//         t6904: title,
//         t6906: userId,
//         up: 1,
//         ...(setXSRFToBody('xsrf'))
//       },
//       { headers: setRequestHeader('token') }
//     )

//     if (data.id) {
//       const createdCompany = {
//         id: +data.id,
//         name: data.val,
//         apps: []
//       }
//       return createdCompany
//     }

//     return null
//   }

//   async get(dbName, id) {
//     const { data } = await getFetcher(
//       `/api/${dbName}/report/${id}?JSON_KV`,
//       { headers: setRequestHeader('token') }
//     )

//     if (!data?.[0]?.company) return []

//     let companies = {}

//     data
//       .forEach(
//         ({
//           company,
//           company_id,
//           app,
//           app_id,
//           app_color,
//           app_icon,
//           app_isDeleted,
//           CompanyIsDeleted
//         }) => {

//           const deletedCompany = CompanyIsDeleted === 'true' ? true : false

//           const newApp = {
//             id: +app_id,
//             title: app,
//             selectedColor: app_color,
//             selectedIcon: `/imgs/icons/decoration/${app_icon}`,
//             companyId: +company_id,
//             isDeleted: (deletedCompany || app_isDeleted === 'true') ? true : false
//           }

//           if (!companies[company]) {
//             companies = {
//               ...companies,
//               [company]: {
//                 id: +company_id,
//                 name: company,
//                 isDeleted: CompanyIsDeleted === 'true' ? true : false,
//                 apps: app ? [newApp] : []
//               }
//             }
//           }
//           else {
//             companies = {
//               ...companies,
//               [company]: {
//                 ...companies[company],
//                 apps: [...companies[company].apps, newApp]
//               }
//             }
//           }
//         }
//       )

//     // return data?.length
//     //   ? Object
//     //     .values(companies)
//     //     .filter(company => !company.isDeleted)
//     //   : []
//     return data?.length
//       ? Object
//         .values(companies)
//       // .filter(company => !company.isDeleted)
//       : []
//   }

//   async update(entityId, dbName, values) {
//     const { data } = await postFetcher(
//       `/api/${dbName}/_m_save/${entityId}`,
//       {
//         ...values,
//         ...(setXSRFToBody('xsrf'))
//       },
//       { headers: setRequestHeader('token') }
//     )

//     return data
//   }

//   async dalete(objectId, dbName) {
//     const { data } = await postFetcher(
//       `/api/${dbName}/_m_save/${objectId}`,
//       {
//         t7330: true,
//         ...(setXSRFToBody('xsrf'))
//       },
//       { headers: setRequestHeader('token') }
//     )
//     if (Array.isArray(data)) return null
//     return data
//   }
// }

export const companyAPI = createApi({
  reducerPath: 'companyAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, headers: setRequestHeader('token') }),
  endpoints: (builder) => ({
    create: builder.mutation({
      query: ({ dbName, title, userId, data }) => {

        const formData = new FormData()
        formData.append('t6904', title)
        formData.append('t6906', userId)
        formData.append('up', 1)
        formData.append('_xsrf', localStorage.getItem('xsrf'))

        return {
          url: `/api/${dbName}/_m_new/6904`,
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (company) => {
        if (company?.id) {
          const createdCompany = {
            id: +company.id,
            name: company.val,
            apps: []
          }
          return createdCompany
        }
        return null
      }
    }),
    get: builder.query({
      query: ({ dbName = 'my', reportId = '6909' }) => ({
        url: `/api/${dbName}/report/${reportId}?JSON_KV`,
        // url: `/api/my/report/7999?JSON_KV`,
        headers: setRequestHeader('token')
      }),
      transformResponse: (companies, meta, arg) => {
        const { error } = companies[0]

        if (error) return null
        if (!companies?.length) return null

        const { actualCompanies, deletedCompaniesApps } = companies
          .reduce((newCompanies, nextCompany) => {

            const {
              company,
              company_id,
              app,
              app_name,
              app_id,
              app_color,
              app_icon,
              app_isDeleted,
              CompanyIsDeleted
            } = nextCompany

            const isDeletedCompany = CompanyIsDeleted === 'true' ? true : false
            const isDeletedApp = (isDeletedCompany || app_isDeleted === 'true') ? true : false

            let newApp = null

            if (app)
              newApp = {
                id: +app_id,
                title: app_name || app,
                dbName: app,
                selectedColor: app_color,
                selectedIcon: `/imgs/icons/decoration/${app_icon}`,
                companyId: +company_id,
                isDeleted: isDeletedApp
              }

            const successfullApp = (newApp && !newApp?.isDeleted)

            if (!isDeletedCompany) {

              return {
                ...newCompanies,
                actualCompanies: {
                  ...newCompanies.actualCompanies,
                  [company]: !newCompanies.actualCompanies[company]
                    ? {
                      id: +company_id,
                      name: company,
                      isDeleted: isDeletedCompany,
                      apps: successfullApp ? [newApp] : []
                    }
                    : {
                      ...newCompanies.actualCompanies[company],
                      apps: successfullApp
                        ? [...newCompanies.actualCompanies[company].apps, newApp]
                        : newCompanies.actualCompanies[company].apps
                    }
                },
                deletedCompaniesApps: newApp?.isDeleted
                  ? [...newCompanies.deletedCompaniesApps, newApp]
                  : newCompanies.deletedCompaniesApps
              }
            }

            if (isDeletedCompany) {
              // if (!successfullApp) {
              return {
                ...newCompanies,
                deletedCompaniesApps: newApp
                  ? [...newCompanies.deletedCompaniesApps, newApp]
                  : newCompanies.deletedCompaniesApps
              }
            }

          },
            {
              actualCompanies: {},
              deletedCompaniesApps: []
            }
          )

        return {
          companies: Object.values(actualCompanies),
          deletedApps: deletedCompaniesApps
        }
        // Object
        //   .values(
        //     companies
        //       .reduce((companies, nextCompany) => {
        //         const {
        //           company,
        //           company_id,
        //           app,
        //           app_id,
        //           app_color,
        //           app_icon,
        //           app_isDeleted,
        //           CompanyIsDeleted
        //         } = nextCompany

        //         const deletedCompany = CompanyIsDeleted === 'true' ? true : false

        //         const newApp = {
        //           id: +app_id,
        //           title: app,
        //           selectedColor: app_color,
        //           selectedIcon: `/imgs/icons/decoration/${app_icon}`,
        //           companyId: +company_id,
        //           isDeleted: (deletedCompany || app_isDeleted === 'true') ? true : false
        //         }

        //         if (!companies[company]) {
        //           return {
        //             ...companies,
        //             [company]: {
        //               id: +company_id,
        //               name: company,
        //               isDeleted: CompanyIsDeleted === 'true' ? true : false,
        //               apps: app ? [newApp] : []
        //             }
        //           }
        //         }
        //         else {
        //           return {
        //             ...companies,
        //             [company]: {
        //               ...companies[company],
        //               apps: [...companies[company].apps, newApp]
        //             }
        //           }
        //         }
        //       }, {})
        //   )
        // const actualCompanies = allCompanies.fil
        // return 
      }
    }),
    update: builder.mutation({
      query: ({ dbName, title, companyId }) => {

        const formData = new FormData()

        formData.append('t6904', title)
        formData.append('up', 1)
        formData.append('_xsrf', localStorage.getItem('xsrf'))
        // formData.append('t6906', userId)

        return {
          url: `/api/${dbName}/_m_save/${companyId}`,
          headers: setRequestHeader('token'),
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (company) => {
        if (!company)
          return {
            name: null
          }

        return {
          name: company.val
        }
      }
    }),
    delete: builder.mutation({
      query: ({ dbName, companyId }) => {

        const formData = new FormData()

        formData.append('t7330', true)
        formData.append('_xsrf', localStorage.getItem('xsrf'))

        return {
          url: `/api/${dbName}/_m_save/${companyId}`,
          headers: setRequestHeader('token'),
          method: 'POST',
          body: formData
        }
      },
      transformResponse: (company) => {
        if (!company)
          return {
            deletedCompanyId: null
          }

        return {
          deletedCompanyId: company.obj
        }
      }
    }),
  })
})

// export const companyService = new CompanyService()