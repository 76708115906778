import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"


const defaultState = {
  user: null,
  isAuthorized: false,
  message: '',
  token: null,
  xsrf: null,
}

const userAdapter = createEntityAdapter()

const userSlice = createSlice({
  name: 'user',
  initialState: defaultState,
  reducers: {
    getUser(state, { payload }) {
      state.user = payload
    },
    login(state, { payload }) {
      state.isAuthorized = payload
    },
    registration(state, { payload }) {
      state.message = payload
    },
    setToken(state, { payload }) {
      // console.log('token', payload)
      state.token = payload
    },
    setXSRF(state, { payload }) {
      state.xsrf = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      // customEntityAdapter.removeAll(state);
      userAdapter.removeAll(state)
    });
  }
})

const userReducer = userSlice.reducer
const userActions = userSlice.actions

export {
  userReducer,
  userActions
}